@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

body {
  margin: 0;
  padding: 0;
}

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

.btn {
  font-weight: bold;
  font-size: 14px;
}

.btn-primary {
  background-color: #15616d !important;
  border-color: #15616d !important;

  &:hover {
    background-color: #114d57 !important;
    border-color: #114d57 !important;
  }
}

a {
  color: #15616d;
  text-decoration: none;

  &:hover {
    color: #15616d;
    text-decoration: underline;
  }
}

.btn-secondary {
  background-color: #139dff !important;
  border-color: #139dff !important;

  &:hover {
    background-color: #1289df !important;
    border-color: #1289df !important;
  }
}

.btn-danger {
  background-color: #e34a58 !important;
  border-color: #e34a58 !important;

  &:hover {
    background-color: #b83a45 !important;
    border-color: #b83a45 !important;
  }
}

.btn-success {
  background-color: #67ce7e !important;
  border-color: #67ce7e !important;

  &:hover {
    background-color: #54af68 !important;
    border-color: #54af68 !important;
  }
}

.btn-warning {
  background-color: #ffd968 !important;
  border-color: #ffd968 !important;

  &:hover {
    background-color: #ecc961 !important;
    border-color: #ecc961 !important;
  }
}

.table {
  border: 1px solid #eee;

  td,
  th {
    vertical-align: middle !important;
    width: 0;
  }
  .tableTop {
    vertical-align: top !important;
  }

  td,
  th,
  h6 {
    margin-bottom: 0px;
  }
}

.close-button {
  border-radius: 4px;
  border: 0;
  background: #e34a58;
  font-weight: bold;
  transition: 0.3s;
  color: #fff;

  &:hover {
    background: #b83a45;
  }
}

.top-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .top-page-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
  }
}

input[type="checkbox"] {
  transform: scale(1.2);
}

.form-error-input {
  border: 1px solid #f00 !important;
}

.margin-40 {
  margin-bottom: 40px !important;
}

.wrapper-logs {
  max-height: 500px;
  overflow-y: auto;
}

// comments area
.list-comments {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 300px;
    overflow-y: auto;

    .comment-item {
      background: #eee;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 10px;

      .comment-author {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .comment-text {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}

.red {
  background: #e66161;

  .btn {
    border: 1px solid #eee !important;
  }
}

.green {
  background: #6dc96d;

  .btn {
    border: 1px solid #eee !important;
  }
}

.orange {
  background: #ff8051;
}

tbody.practice-list > tr:not(.green):not(.red):not(.orange) {
  background: rgba(0, 0, 0, 0.05) !important;
}

.text-right {
  text-align: right;
}

.mr-1 {
  margin-right: 2px;
}

.mr-2 {
  margin-right: 4px;
}

.mr-4 {
  margin-right: 16px;
}

.ml-1 {
  margin-left: 2px;
}

.ml-2 {
  margin-left: 4px;
}

.ml-4 {
  margin-left: 16px;
}
