.relativeChips {
    border: 2.5px solid #15616d;
    border-radius: 4px;
}

.verticalChipDivider {
    border-right: 2.5px solid #15616d;
}

.closeArea:hover {
    cursor: pointer;
}