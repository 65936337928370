@media (min-width: 1400px) {
    .wrapper-pages{
        flex: 0 0 85%;
		max-width: 85%;
    }
}

@media (max-width: 1400px) {
    .wrapper-pages{
        flex: 0 0 100%;
		max-width: 100%;
		top: 50px;
    }
}

.wrapper-pages{
	height: 100%;
	background: #fff;
	padding: 20px;
	position: relative;
	overflow: auto;
}