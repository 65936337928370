.new-memo-input{

}

.memo-box{
    background-color: rgb(255, 249, 138);
    border-radius: 4px;
    padding:4px;
    margin:5px 0px;
}

.memo-header{
    font-size: 0.8em;
}

.memo-content{
    font-style: italic;
}

.memos-inner-container{
    max-height: 60vh;
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: auto;
}

.memos-container{
    padding: 8px;
    margin-bottom:20px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
    background: #888; 
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}