.customers-page {
    height: 100%;
}


@media screen and (max-width: 768px) {

    .customers-page {
        height: auto
    }

}