.separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 0.5px solid #000;
  }
  
  .separator:not(:empty)::before {
    margin-right: 1rem;
  }
  
  .separator:not(:empty)::after {
    margin-left: 1rem;
  }