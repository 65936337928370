.page-link.btn-primary {
    color: #fff;
}

.page-link {
    min-width: 50px;
}

.customerFilter {
    .btn-group {
        margin-bottom: 0px;
        flex: 1;
    }
}

.list-customers-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.selfSpaceAssing {
    flex: 1 0 auto;
}

.table-responsive {
    flex: 1;
}

.clientFilter {
    button {
        height: 38px;
    }
}

@media screen and (max-width: 768px) {
    .topbar {
        flex-direction: column;
    }

    .customerFilter {
        .btn-group {
            display: block;
            min-width: -webkit-fill-available;
        }

        .btn-group .btn {
            display: block;
            float: none;
            width: 100%;
        }
    }

    .filterBar {
        flex-direction: column;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .exportArea {
        flex-direction: column;
        gap: 1rem;
    }

    .clientFilter {
        button {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    .selectArea {
        select {
            width: 100% !important;
            margin-bottom: 1rem;
        }
    }

}

@media screen and (max-width: 992px) {
    .filterBar {
        gap: 1rem;
    }

    .exportArea {
        gap: 1rem;
    }
}