.serviceInfo {
  background-color: #f8f9fa;
}

.serviceInfo_element {
  display: flex;
  align-items: center;
  gap:0.5rem
}

.expired {
  color: red;
}

.divider {
  border-right: 1px solid black;
}

@media screen and (max-width: 768px) {
  .divider {
    display: none !important;
  }

  .serviceInfo {
    flex-direction: column;
  }
}