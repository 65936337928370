@media (max-width: 1400px) {
    .sidebar-main{
        max-width: 0%;
        position: fixed;
        left:0;
        z-index: 101;
        padding:0px;
        transition: max-width 0.3s linear;
        transition: padding 0.3s linear;
    } 
    
    .sidebar-main.sidebar-expanded{
        max-width:85%;
        padding:25px;
    } 
}

@media (min-width: 1400px) {
    .sidebar-main{
        flex: 0 0 15%;
	    max-width: 15%;
        padding: 25px;
    }    
}

.sidebar-main{
	height: 100vh;
	background: #15616d;
    z-index: 101;
	border-right: 1px solid #000;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

@media (min-width: 1400px) {
    .ethos-navbar{
        display: none
    }
}

.navbar-title a{
    color: #fff;
    text-decoration: none;
    font-size: 22px;
    font-weight: 900;
}

.navbar-title a:visited { 
    color: #fff;
}
.sidebar-main a:visited { 
    color: #fff;
}

.navbar-logout-icon{
    font-size: 1.5em;
}

.navbar-row{
    height: 100%;
    padding:10px;
    padding-right: 30px;
    align-items: center;
}


.ethos-navbar{
	width: 100vw;
    height: 50px;
	background: #15616d;
	border-bottom: 1px solid #000;
    overflow: hidden;
    z-index: 98;
    position: absolute;
    top: 0;
}

@media (min-width: 1400px) {
    .sidebar-overlay{
        display:none
    }
}

.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.6);
    z-index:100;
}

.sidebar-main::-webkit-scrollbar {
    display: none;
}

.sidebar-main hr{
    border-top: 1px solid #0002;
    z-index: 99999;
    width: 100%;
}

.sidebar-main a{
    color: #fff;
    text-decoration: none;
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 0!important;
    margin-top: 0!important;
}

.sidebar-link{
    display: block;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 5px 14px;
	color: #eee;
	font-size: 18px;
	transition: .4s ease;
}

.sidebar-link:hover {
    background: #105560;
    color: #fff;
    text-decoration: none
}

.sidebar-link span {
    margin-left: 15px;
    font-weight: 600;
}