.breadcrumb.arrow-breadcumb>span+span:before { 
    padding-right: 0.5rem; 
    color: rgb(21, 17, 17);
     content: ">"; 
}

.low-opacity{
    opacity: 0.2
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, ">");
}