:root {
    --circle-size: clamp(1.5rem, 5vw, 3rem);
    --spacing: clamp(0.25rem, 2vw, 0.5rem);
  }
  
  .c-stepper {
    display: flex;
  }
  
  .c-stepper__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    opacity: 0.4;
  }

  .c-stepper__item:not(.current):not(.blocked) {
    cursor: pointer;
  }
  
  .c-stepper__item.blocked {
    cursor: not-allowed;
  }

  .c-stepper__item.current {
    opacity: 1;
  }

  .c-stepper__circle {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    margin: 0 auto 1rem;
  }

  .c-stepper__circle.current:not(.completed) {
    background-color: blue;
  }  

  .c-stepper__circle.completed {
    background-color: green;
  }

  .c-stepper__circle.blocked {
    background-color: gray;
  }

  .c-stepper__circle.active {
    background-color: rgb(101, 231, 248);
  }

  .c-stepper__item:not(:last-child):after {
    content: "";
    position: relative;
    top: calc(var(--circle-size) / 2);
    width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
    left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
  }
  
  .c-stepper__desc {
    color: black;
    font-size: clamp(0.85rem, 2vw, 1rem);
    padding-left: var(--spacing);
    padding-right: var(--spacing);
    color: grey;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .c-stepper__circle-icon{
    color: white;
    position: relative;
    top:25%;
    height: 50%;
    width: 35% !important;
  }
  
  .wrapper {
    max-width: 1000px;
    margin: 2rem auto 0;
  }