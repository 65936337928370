.ethos-loader-bg{
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    z-index: 3000;
}

.ethos-loader-spinner {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 3001;
    width: 10vw;
    height: 10vw;
    margin-left: -5vw;
}